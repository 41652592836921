import styled, {css} from "styled-components";

export const BaseButton = styled.button`
  font-size: var(--font-size-14);
  font-weight: 500;
  color: var(--text-link);
  cursor: pointer;
  line-height: 20px;
  border: none;
  background: unset;
  padding: 0;
  position: relative;
  white-space: nowrap;
  min-width: ${({minWidth}) => minWidth ? minWidth + 'px': "auto"};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .btn-label {
    visibility: ${({loading}) => loading ? "hidden": "visible"}
  }

  i.btn-loader {
    --fa-animation-duration: 0.5s;
    --loading-icon-width: 18px;
    --loading-icon-height: 18px;
    position: absolute;
    top: calc(50% - (var(--loading-icon-height) / 2));
    left: calc(50% - (var(--loading-icon-width) / 2));
  }

  .btn-label {
    visibility: ${({loading}) => loading ? "hidden": "visible"}
  }

  i.btn-loader {
    --fa-animation-duration: 0.5s;
    --loading-icon-width: 18px;
    --loading-icon-height: 18px;
    position: absolute;
    top: calc(50% - (var(--loading-icon-height) / 2));
    left: calc(50% - (var(--loading-icon-width) / 2));
  }

  i.left-btn-icon, i.right-btn-icon {
    --btn-icon-height: 16px;
    position: absolute;
    top: calc(50% - (var(--btn-icon-height) / 2));
  }

  i.left-btn-icon {
    left: var(--spacing-md);
  }

  i.right-btn-icon {
    right: var(--spacing-md);
  }
`;

export const RedButton = styled(BaseButton)`
  color: var(--color-functional-danger500);
`;

export const PrimaryButton = styled(BaseButton)`
  color: var(--color-base-white);
  background: var(--color-primary-500);
  border-radius: var(--radius-full);
  padding: var(--spacing-sm) var(--spacing-lg);
  box-sizing: border-box;
  height: 40px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background: #999999;
    pointer-events: none;
  }
`;

export const DangerButton = styled(PrimaryButton)`
  background: var(--color-functional-danger500);
`;

export const BorderedButton = styled(BaseButton)`
  border-radius: var(--radius-full);
  border: 1px solid var(--text-link);
  padding: var(--spacing-sm) var(--spacing-lg);
  flex-shrink: 0;

  &:disabled {
    opacity: 1;
    cursor: default;
    border-color: var(--button-primary-label-disabled);
    color: var(--button-primary-label-disabled);
  }
  
  ${({ alertButton }) =>
      alertButton &&
      css`
        min-width: 190px;
        @media (max-width: 800px) {
          margin: 0;
          padding: 0;
          height: auto;
          border: none;
          min-width: auto;
        }
        `}
`;

export const BorderedButtonSmall = styled(BorderedButton)`
    line-height: 15px;
`;

export const DarkBorderedButton = styled(BorderedButton)`
  border-color: var(--text-primary);
  color: var(--text-primary);
`;

export const InfoBorderedButton = styled(BorderedButton)`
  border-color: var(--text-link);
  color: var(--text-link);
`;

export const DangerBorderedButton = styled(BorderedButton)`
  border-color: var(--color-functional-danger500);
  color: var(--color-functional-danger500);
`;

export const SuccessBorderedButton = styled(BorderedButton)`
  border-color: var(--text-positive);
  color: var(--text-positive);
`;

export const UpdateInvoiceDetails = styled(DarkBorderedButton)`
  min-width: 190px;
  line-height: 24px;
`;

export const ChangeLanguage = styled(BaseButton)`
  white-space: nowrap;
  
  i {
    margin-left: var(--spacing-sm);
    font-size: var(--icon-base);
    position: relative;
    top: 1px;
  }
`;

export const CircleIconButton = styled(BaseButton)`
  border-radius: var(--radius-full);
  background: var(--color-grey-100);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  flex-shrink: 0;
`;

export const PlusIconBtn = styled(CircleIconButton)`
  color: var(--text-positive);
  width: 36px;
  height: 36px;
`;

export const MinusIconBtn = styled(CircleIconButton)`
  color: var(--text-danger);
  width: 36px;
  height: 36px;
`;

const ActionButtonOld = styled.a`
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  color: #fff;
  font-family: Roboto, Arial, Tahoma, Helvetica, sans-serif !important;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  position: relative;
  min-width: auto;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  border: none;
  opacity: 1;
  background: #17bed0;
  transition: all 250ms ease-in-out;
  transition-delay: 0ms;
  display: block;
  width: 100%;
  opacity: ${({disabled}) => disabled ? 0.5: 1};

  .btn-label {
    visibility: ${({loading}) => loading ? "hidden": "visible"}
  }

  i.btn-loader {
    --fa-animation-duration: 0.5s;
    --loading-icon-width: 18px;
    --loading-icon-height: 18px;
    position: absolute;
    top: calc(50% - (var(--loading-icon-height) / 2));
    left: calc(50% - (var(--loading-icon-width) / 2));
  }

  i.left-btn-icon, i.right-btn-icon {
    --btn-icon-height: 16px;
    position: absolute;
    top: calc(50% - (var(--btn-icon-height) / 2));
  }

  i.left-btn-icon {
    left: 14px;
  }

  i.right-btn-icon {
    right: 14px;
  }
`;

export const CancelButton = styled(ActionButtonOld)`
  color: #444444;
  background: #e9e9e9;
`;

export const ModalCancelButton = styled(CancelButton)``;

export const ModalActionButton = styled(ActionButtonOld)`
    color: #fff;
    background: #86c600;
`;

export const ModalPrimaryButton = styled(ActionButtonOld)`
    color: #fff;
    background: #17bed0;
`;

export const ModalDangerButton = styled(ActionButtonOld)`
  color: #fff;
  background: #ff4242;
`;