import { __ } from "../../lib/translate";
import hashManager from "../../lib/hashManager";
import { renderQuota } from "@pcloud/web-utilities/dist/plans/utils.plans";
import { strFitMail } from "../../lib";

const eventKeys = {
  modifyuserinfo: ['Account change'],

  // File Operations
  createfile: ['File added'],
  deletefile: ['File deleted'],
  modifyfile: ['File modified'],

  createfolder: ['Folder created'],
  deletefolder: ['Folder deleted'],
  modifyfolder: ['Folder modified'],

  // Share Operations
  requestshareout: ['Share request sent'],
  cancelledshareout: ['Cancelled share request'],
  acceptedshareout: ['Your share request was accepted'],
  declinedshareout: ['Your share request was declined'],
  removedshareout: ['Your share request was removed'],
  modifiedsharein: ['Share permissions were modified'],
  requestsharein: ['Received share request'],
  cancelledsharein: ['Cancelled share request'],
  acceptedsharein: ['You accepted share request'],
  declinedsharein: ['You declined share request'],
  removedsharein: ['Share with you was removed'],
  modifiedshareout: ['Share permissions were modified'],

  // Business shares
  create_teamshare: ['Folder shared with team'],
  establishbshareout: ['Business Share created'],
  modifybshareout: ['Folder Sharing Settings Modified'],
  establishbsharein: ['Received Business Share'],
  modifybsharein: ['Business Share Changed'],
  removebshareout: ['Stopped Business Share'],
  removebsharein: ['Stopped Business Share'],
  remove_teamshare: ['Stopped Business Share'],
  modify_teamshare: ['Modify Business Share'],

  // User operations
  modify_user: ['User Modified'],
  modify_user_paswordreset: ['Reset Password'],
  modify_user_avatarchange: ['Uploaded Avatar'],
  modify_user_settings: ['Modify User Settings'],

  modify_user_status_activate: ['Activate User'],
  modify_user_status_deactivate: ['Deactivate User'],

  link_device: ['Linked Device'],
  unlink_device: ['Unlinked Device'],
  join_user: ['User Joined Account'],

  send_invitation: ['Account Invite'],
  cancel_invitation: ['Invitation Cancelled'],

  // Team operations
  create_team: ['Team Created'],
  modify_team: ['Modify Team'],
  modify_team_status: ['Team Status Changed'],
  modify_team_settings: ['Team Settings Changed'],
  add_teamlink_invite: ['Team Invitation'],
  add_teamlink_add: ['Added to Team'],
  modify_teamlink: ['Change Teamlink'],
  join_user_team: ['User added to team'],
  remove_user_team: ['User remvoed from team'],

  invite_user_team: ['User invited to Team'],

  modify_user_team_head_add: ['User becomes head of team'],
  modify_user_team_head_remove: ['User removed as head of team'],
  modify_user_team_user_activate: ['User activated'],
  modify_user_team_user_deactivate: ['User deactivated'],
  modify_user_team_team_activate: ['Team activated'], // We may not use this because it duplicates modify_team_status_activate
  modify_user_team_team_deactivate: ['Team deactivated'], // We may not use this because it duplicates modify_team_status_deactivate
  modify_team_status_activate: ['Team activated'],
  modify_team_status_deactivate: ['Team deactivated'],

  // Account Operations
  modify_account_settings: ['Change Global Settings'],
  modify_account_quota: ['Change Quota'],
  modify_billinginfo: ['Modify Billing'],
  create_account: ['Create Account'],
  modify_invoiceinfo: ['Modify Invoice Info'],
  modify_account: ['Modify Account'],
  modifyaccountinfo: ['Modify Account Info'],

  view_asother: ['View as Other'],
  login_asother: ['login_as_text', 'Logged as Other'],

  // comments
  comment_post: ['New Comment'],
  comment_delete: ['Comment Deleted']
}

export const getUserStatus = (data) => {
  if (data.active == -1) {
    return 'invited';
  } else if (data.active) {
    return 'active';
  } else {
    if (data.frozen) {
      return 'deactivated';
    } else {
      return 'deleted';
    }
  }
}

export const usersCalc = (users) => {
  let all = 0, active = 0, n = 0;
  for (; n < users.length; ++n) {
    if (users[n].active || users[n].frozen)
      active++;
    all++;
  }
  return { all: all, active: active };
}

export const teamsCalc = (teams) => {
  let all = 0, active = 0, n = 0;
  for (; n < teams.length; ++n) {
    if (teams[n].active)
      active++;
    all++;
  }
  return { all: all, active: active, inactive: (all - active) };
};

export const inviteUserDisableInfo = () => {
  var returnData = {isDisabled: false, tooltip: ""};
  var accountInfo = HFN.state.getState().business.accountInfo;
  var meminfo = accountInfo.account.memberinfo;
  var permissions = HFN.config.user.account.permissions;

  var ownerTotalQuota = accountInfo.account.owner.quota;
  var ownerUsedQuota = accountInfo.account.owner.usedquota;
  var ownerFreeQuota = ownerTotalQuota - ownerUsedQuota;

  if (meminfo.maximum <= meminfo.current) {
    returnData.isDisabled = true;
    if (permissions.account_manage) {
      returnData.tooltip = __("invite_business_reaced_manage_tooltip", "You have reached your user quota. Please upgrade your user quota from the Billing settings.");
    } else {
      returnData.tooltip = __("invite_business_reaced_tooltip", "You have reached your user quota. Please contact the Business owner.");
    }
  } else if (
    meminfo.maximum - meminfo.current <= 1 ||
    (meminfo.current / meminfo.maximum * 100 >= 90)
  ) {
    if (permissions.account_manage) {
      returnData.tooltip = __("invite_business_about_to_reaced_manage_tooltip", "You are about to reach your user quota. Please upgrade your user quota from the Billing settings.");
    } else {
      returnData.tooltip = __("invite_business_about_to_reaced_tooltip", "You are about to reach your user quota. Please contact the Business owner.");
    }
  } else if (ownerFreeQuota < HFN.BUSINESS_MIN_FREE_OWNER_QUOTA) {
    returnData.isDisabled = true;
    returnData.tooltip = __("business_owner_invite_failed_nostorage");
  }

  return returnData;
}

export const getBusinessUserName = (item) => {
  if (item.firstname && item.lastname) {
    return item.firstname + " " + item.lastname;
  }
  return item.email;
}

export const getBusinessTeamUsers = (item) => {
  let n = 0, active = 0, inactive = 0, invited = item.invites || 0;
  for ( ; n < item.members.length; ++n) {
    if (item.members[n].link.invited)
      invited++
    else if (item.members[n].link.user_active)
      active++;
    else
      inactive++;
  }

  return  __('%act% active, %deact% deactivated').replace('%act%', active).replace('%deact%', inactive);
}

export const getBusinessTeamHead = (item) => {
  if (item.id) {
    for (let n = 0; n < item.members.length; ++n) {
      if (item.members[n].link.head) {
        return [
          htmlentities(item.members[n].user.firstname + ' ' + item.members[n].user.lastname),
          '(' + htmlentities(item.members[n].user.email) + ')'
        ];
      }
    }
  }

  return '-';
}

export const getBusinessUserStatus = (data) => {
  return data.status.toUpperCaseFirst();
};

export const getBusinessUserTeamStatus = (data) => {
  return data.head ? __('Head') : __('Member');
};

export const getBusinessTeamsStatus = (data) => {
  if (!data.id) {
    return __('Active');
  }

  return data.active ? __('Active') : __('Deactivated');
};

export const getBusinessBusinessQuota = (item) => {
  if (item.invited && item.storage) {
    return renderQuota(item.storage);
  }

  return renderQuota(item.usedquota) + " / " + renderQuota(item.quota);
};

export const onBusinessUserClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey)
    return;

  event.stopPropagation();

  if (!data.invited) {
    hashManager.pushState({ page: 'b_user', "tab-user": 'userinfo', id: data.id });
  }
};

export const onBusinessTeamHeadClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey)
    return;

  event.stopPropagation();

  if (data.id) {
    for (let n = 0; n < data.members.length; ++n) {
      if (data.members[n].link.head) {
        hashManager.pushState({ page: 'b_user', "tab-user": 'userinfo', id: data.members[n].user.id });
        break;
      }
    }
  }
};

export const onBusinessTeamClick = (event, data) => {
  if (event.ctrlKey || event.shiftKey || event.metaKey)
    return;

  event.stopPropagation();

  if (!data.invited) {
    hashManager.pushState({ page: 'b_team', id: data.id });
  }
};

export const getBusinessEventName = (event) => {
  const eventId = getEventId(event);
  return eventKeys[eventId] ? __(...eventKeys[eventId]) : eventId;
}

export const getEventId = (event) => {
  let evname = event.event;
  if (evname == 'modify_user' && event.obj.data.password_reset)
    evname = 'modify_user_paswordreset';
  else if (evname == 'modify_user' && event.obj.data.avatar_change)
    evname = 'modify_user_avatarchange';
  else if (evname == 'add_teamlink' && event.obj.data.settings.invited)
    evname = 'add_teamlink_add';
  else if (evname == 'add_teamlink' && !event.obj.data.settings.invited)
    evname = 'add_teamlink_invite';
  else if (evname == 'modify_user_status' && event.obj.data.active)
    evname = 'modify_user_status_activate';
  else if (evname == 'modify_user_status' && !event.obj.data.active)
    evname = 'modify_user_status_deactivate';

  else if (evname == 'modify_user_team' && 'head' in event.obj.data)
    evname = event.obj.data.head ? 'modify_user_team_head_add' : 'modify_user_team_head_remove';
  else if (evname == 'modify_user_team' && 'useractive' in event.obj.data)
    evname = event.obj.data.useractive ? 'modify_user_team_user_activate' : 'modify_user_team_user_deactivate';
  else if (evname == 'modify_user_team' && ('teamactive' in event.obj.data || 'team_active' in event.obj.data))
    evname = event.obj.data.teamactive ? 'modify_user_team_team_activate' : 'modify_user_team_team_deactivate';

  else if (evname == 'modify_team_status')
    evname = event.obj.data.active ? 'modify_team_status_activate' : 'modify_team_status_deactivate';

  return evname;
}

export const getLogsDefaultDates = (registered) => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const toDate = new Date(y, m, 1);
  const registeredDate = new Date(registered);
  return {
    from: registered && registeredDate > toDate ? registeredDate : toDate,
    to: new Date(y, m + 1, 0),
  }
}

export const buildUserinfo = (data, mailLength) => {
  mailLength = mailLength || 500;
  return data.firstname + ' ' + data.lastname + ' (' + strFitMail(data.email, mailLength) + ')';
}