// @flow

import React from "react";
import { __ } from "../../lib/translate";
import * as ICONS from "./icons";

export const actionsGallery = {
  image: [
    // "download",
    // "getpublink",
    ["openloc",
    "open-orig",
    "downloadsizes"],
    //--------------
    ["delete",
    "move",
    "copy",
    "rename"],
    //--------------
    ["info",  
    "revisions"]
    // ["print"],
  ],
  video: [
    // "download",
    // "getpublink",
    ["openloc",
    "open-orig"],
    //--------------
    ["delete",
    "move",
    "copy",
    "rename"],
    //--------------
    ["info",  
    "revisions"]
    // ["print"],
  ]
};

export const actionsGalleryToIcon = {
  downloadsizes: <ICONS.DownloadResizedIcon />,
  print: <ICONS.PrintIcon />,
  copy: <ICONS.CopyIcon />,
  move: <ICONS.MoveIcon />,
  rename: <ICONS.RenameIcon />,
  info: <ICONS.FileInfoIcon />,
  revisions: <ICONS.RevisionsIcon />,
  openloc: <ICONS.OpenLocationIcon />,
  "open-orig": <ICONS.OpenOriginalIcon />,
  delete: <ICONS.DeleteIcon />
};

export const actionsGalleryToName = {
  downloadsizes: __("Download Resized", "Download resized"),
  print: __("Print", "Print"),
  copy: __("Copy", "Copy"),
  move: __("Move", "Move"),
  rename: __("Rename", "Rename"),
  info: __("File Info", "File info"),
  revisions: __("Revisions", "Revisions"),
  openloc: __("Open Location", "Open location"),
  delete: __("Delete", "Delete"),
  "open-orig": __("Open Original", "Open original")
};


// Slideshow options in milliseconds
export const SLIDESHOW_OPTIONS = [
  3000, // 3 sec
  5000, // 5 sec
  10000, // 10 sec
  20000, // 20 sec
  30000, // 30 sec
  60000, // 60 sec
];

export const HLS_VIDEO_VARIANTS = {
  landscape: [
    { type: "qHD", width: 960, height: 540 },
    { type: "HD", width: 1280, height: 720 },
    { type: "FHD", width: 1920, height: 1080 }
  ],
  portrait: [
    { type: "qHD", width: 540, height: 960 },
    { type: "HD", width: 720, height: 1280 },
    { type: "FHD", width: 1080, height: 1920 }
  ]
};

export const MAX_VIDEO_FRAME_SIZE = "FHD";

export const NUM_RETRIES_ON_ERROR = {
  image: 1,
  video: 2,//MIN: 2; HLS retries + one more for going to transcodedVideo counter,
  videoThumb: 1,
  transcodedVideo: 1
};

export const HLS_DEFAULT_VIDEO_QUALITY = "qHD";

// Fine Tuning HLS support
export const HLS_CONFIG = {
  // Refers always to 960x540. Otherwise findBestLevel could be used and define higher level depending on video bitrate.
  startLevel: 0,
  // Due this issue: https://github.com/vidstack/player/issues/1521
  preferManagedMediaSource: false,
  debug: true,
  maxBufferLength: 3,
  maxBufferSize: 10,
  maxMaxBufferLength: 6,
  manifestLoadPolicy: {
    default: {
      maxTimeToFirstByteMs: Infinity,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 2,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
      errorRetry: {
        maxNumRetry: 0,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      }
    }
  },
  playlistLoadPolicy: {
    default: {
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 2,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
      errorRetry: {
        maxNumRetry: 0,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
    },
  },
  fragLoadPolicy: {
    default: {
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 120000,
      timeoutRetry: {
        maxNumRetry: 4,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
      errorRetry: {
        maxNumRetry: 0,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      }
    }
  },
  keyLoadPolicy: {
    default: {
      maxTimeToFirstByteMs: 8000,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 1,
        retryDelayMs: 1000,
        maxRetryDelayMs: 20000,
        backoff: 'linear',
      },
      errorRetry: {
        maxNumRetry: 0,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      }
    }
  },
  steeringManifestLoadPolicy: {
    default: {
      maxTimeToFirstByteMs: 10000,
      maxLoadTimeMs: 20000,
      timeoutRetry: {
        maxNumRetry: 2,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
      errorRetry: {
        maxNumRetry: 0,
        retryDelayMs: 0,
        maxRetryDelayMs: 0,
      },
    },
  }
};

export const MUTED_ON_AUTOPLAY_FAILED_STORAGE_KEY = "mutedOnAutoplayFailed";

export const SWIPE_THRESHOLD = 50;// Min 50px should be touch distance to define we're swiping.
export const MIN_ZOOM = 100;
export const MAX_ZOOM = 500;
export const ZOOM_STEP = 10;
