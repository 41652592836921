import React from "react";
import styled from "styled-components";

import { Btn } from "./ButtonDefault";
import TString from "./intl/TString";
import apiMethod from "../api/apiMethod";
import { __ } from "../lib/translate";
import { setcookie, rcookie } from "../lib/utils";
import { initTracking, GA_FLAG, FBQ_FLAG } from "../lib/tracking";
import { siteUrl } from "@pcloud/web-utilities/dist/config";
import CookieConsentModal from "../components/CookieConsentModal";

const showErrorAlert = (...args) => {
	HFN.message(...args);
};

class CookieConsent extends React.Component {
	constructor(props) {
		super(props);

		const defaultFlags = GA_FLAG | FBQ_FLAG;
		//console.log(defaultFlags);

		//const hasGa = (defaultFlags & trackingSettings["ga"]) == trackingSettings["ga"];
		//const hasFbq = (defaultFlags & trackingSettings["fbq"]) == trackingSettings["fbq"];
		//console.log('HAS TRACKING SETTINGS ', hasGa, hasFbq);

		this.state = {
			opened: false,
			isAccepted: false,
			//isCheckedGa: localStorage.getItem('ga-cookies') === null ? true : JSON.parse(localStorage.getItem('ga-cookies')),
			//isCheckedFb: localStorage.getItem('fbq-cookies') === null ? true : JSON.parse(localStorage.getItem('fbq-cookies')),
			//hasTrackingSettings: localStorage.getItem('settings-cookies') === null ? defaultFlags : JSON.parse(localStorage.getItem('settings-cookies')),
			hasTrackingSettings: rcookie('cookieconsent') === null ? defaultFlags : rcookie('cookieconsent')
		};
		this.baseState = this.state;
		console.log('state >>>>>>>>> ', this.state);

		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
		this.onChangeCookieGa = this.onChangeCookieGa.bind(this);
		this.onChangeCookieFb = this.onChangeCookieFb.bind(this);
		this.updateConsentInfo = this.updateConsentInfo.bind(this);
		this.sendCookieConsent = this.sendCookieConsent.bind(this);
		this.onApiSuccessCallback = this.onApiSuccessCallback.bind(this);
	}

	open() {
		this.setState({ opened: true });
		console.log("STATE >>>>> ", this.state);
	}

	close() {
		console.log("close");
		this.resetForm();
		this.setState({ opened: false });
	}

	resetForm() {
		this.setState(this.baseState);
	}

	onChangeCookieGa() {
		/*const {isCheckedGa} = this.state;

		this.setState(prevState => ({
			isCheckedGa: !prevState.isCheckedGa
		}));*/

		const { hasTrackingSettings } = this.state;

		this.setState({
			hasTrackingSettings: (hasTrackingSettings ^ GA_FLAG)
		});

		console.log('Tracking', hasTrackingSettings);
	}

	onChangeCookieFb() {
		/*const {isCheckedFb} = this.state;

		this.setState(prevState => ({
			isCheckedFb: !prevState.isCheckedFb
		}));*/

		const { hasTrackingSettings } = this.state;

		this.setState({
			hasTrackingSettings: (hasTrackingSettings ^ FBQ_FLAG)
		});
	}

	updateConsentInfo() {
		//const {isCheckedGa, isCheckedFb} = this.state;

		//localStorage.setItem('ga-cookies', isCheckedGa);		
		//localStorage.setItem('fbq-cookies', isCheckedFb);

		const { hasTrackingSettings } = this.state;
		//localStorage.setItem('settings-cookies', hasTrackingSettings);
		setcookie('cookieconsent', hasTrackingSettings, 365);

		this.sendCookieConsent();

		this.baseState = this.state;
		this.setState({ opened: false });
	}

	onApiSuccessCallback = params => {
		this.sendCookieConsent();
	}

	sendCookieConsent() {
		this.setState({ isAccepted: true });
		
		apiMethod(
			"agreewithcookies",
			{},
			ret => {
				//set cookie
				if (rcookie('cookieconsent') == null) {
					setcookie("cookieconsent", 3, 365);
				}

				//google analitycs, facebook, twitter
				initTracking([
					{
						type: GA_FLAG,
						onStart: this.props.initAnalyticsTracking,
						onDisabled: () => {
							window.ga4 = () => {};
						}
					},
					{
						type: FBQ_FLAG,
						onStart: () => {
							if (this.props.initFacebookTracking && typeof this.props.initFacebookTracking === "function") {
								this.props.initFacebookTracking();
							}
							if (this.props.initTwitterTracking && typeof this.props.initTwitterTracking === "function") {
								this.props.initTwitterTracking();
							}
							if (this.props.initTiktokTracking && typeof this.props.initTiktokTracking === "function") {
								this.props.initTiktokTracking();
							}
							if (this.props.initPinterestTracking && typeof this.props.initPinterestTracking === "function") {
								this.props.initPinterestTracking();
							}
						},
						onDisabled: () => {
							window.fbq = function() {};
							window.twq = function() {};
							// window.ttq = {track: function() {}};
						}
					}
				]);

				//unmount component
				if (this.props.onAgreed && typeof this.props.onAgreed === "function") {
					this.props.onAgreed();
				}
			},
			{
				errorCallback: ret => {
					showErrorAlert(ret.error, "error");
				}
			}
		);
	}

	render() {
		const { opened, isAccepted, hasTrackingSettings } = this.state;
		const hasGa = (hasTrackingSettings & GA_FLAG) == GA_FLAG;
		const hasFbq = (hasTrackingSettings & FBQ_FLAG) == FBQ_FLAG;

		return (
			<div>
				{!opened && !isAccepted ? (
					<WrapperCookieConsent className="cookie-consent-wrapper">
						<div>
							<TString
								id="cookie_consent"
								text="This website uses cookie to give you the best, most relevant
								experience. We do not use cookies to collect Personal Information.
								Using this website means that you agree with this."
							>
								{" "}
							</TString>
							<a href={siteUrl("privacy_policy.html")} target="_blank">
								<TString
									id="learn_more_cookie_policy"
									text="Learn more about our cookie policy."
								>
									{" "}
								</TString>
							</a>
							<WrapperButtons>
								<ButtonStyled
									inline
									size="medium"
									color="transparent"
									onClick={this.open}
								>
									{__("cookie_settings", "Cookie Settings")}
								</ButtonStyled>
								<TransformButton
									inline
									size="medium"
									color="cyan"
									onClick={this.sendCookieConsent}
								>
									{__("I Accept", "I Accept")}
								</TransformButton>
							</WrapperButtons>
						</div>
					</WrapperCookieConsent>
				) : null }
				<CookieConsentModal
					hasGa={hasGa}
					hasFbq={hasFbq}
					opened={this.state.opened}
					close={this.close}
					onChangeCookieGa={this.onChangeCookieGa}
					onChangeCookieFb={this.onChangeCookieFb}
					updateConsentInfo={this.updateConsentInfo}
				/>
			</div>
		);
	}
}

export default CookieConsent;

const WrapperCookieConsent = styled.div`
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 20000;
	width: 100%;
	color: #fff;
	background-color: rgba(0,0,0,0.82);
	padding: 20px;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	line-height: 22px;
	text-align: left;
	box-sizing: border-box;

	& > div {
		max-width: 1180px;
		box-sizing: border-box;
		padding-right: 310px;
		position: relative;
		margin: 0 auto;

		& > span {
			color: #fff;
		}
	}

	& > div > a {
		color: #17bed0;
		text-decoration: none;
	}

	@media only screen and (max-width: 550px) {
		& > div {
			padding: 0;
		}
	}
`;

const WrapperButtons = styled.div`
	float: right;
	overflow: hidden;
	position: absolute;
	top: 50%;
	right: 0;
	margin: -17px auto;

	@media only screen and (max-width: 550px) {
		float: none;
		text-align: center;
		display: block;
		position: relative;
		margin: 15px 0 0 0;
	}
`;

const ButtonStyled = styled(Btn)`
	span {
		color: #fff;
		text-decoration: none;
	}
`;

const TransformButton = styled(Btn)`
	text-decoration: none !important;
	& span {
		color: #fff;
	}
`;
