import { createSlice } from "@reduxjs/toolkit";
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

const initialState = {
    accountInfo: { account: {} },
    billingInfo: {},
    users: [],
    invited_users: [],
    teams: []
};

export const businessSlice = createSlice({
    name: "business",
    initialState: initialState,
    reducers: {
        loadAccountInfo: (state, action) => {
            state.accountInfo = action.payload;
        },
        clearAccountInfo: (state) => {
            state.accountInfo = {};
        },
        updateCompanyInfo: (state, action) => {
            state.accountInfo = {
                ...state.accountInfo,
                account: { ...state.accountInfo.account, ...action.payload }
            };
        },
        loadBillingInfo: (state, action) => {
            state.billingInfo = action.payload;
        },
        updateBillingInfo: (state, action) => {
            state.billingInfo = {
                ...state.billingInfo,
                ...action.payload,
                processing: false
            };
        },
        clearBillingInfo: (state) => {
            state.billingInfo = {};
        },
        setProcessingProfile: (state) => {
            state.billingInfo = { ...state.billingInfo, processing: true };
        },
        loadUsers: (state, action) => {
            state.users = action.payload;
        },
        loadTeams: (state, action) => {
            state.teams = action.payload;
        },
        clearUsers: (state) => {
            state.users = [];
        },
        clearTeams: (state) => {
            state.teams = [];
        },
        editUser: (state, action) => {
            state.users = _editUser(state, action.payload);
        },
        loadInvitedUsers: (state, action) => {
            state.invited_users = action.payload;
        },
        clearInvitedUsers: (state, action) => {
            state.invited_users = [];
        },
        editTeam: (state, action) => {
            state.teams = _editTeam(state, action.payload);
        }
    }
});

const _editTeam = (state, team) => {
    const { teams } = state;
    const { id: teamId } = team;

    if (teamId) {
        const updatedTeams = teams.map(businessTeam => {
            const { id } = businessTeam;
            if (id === teamId) {
                return { ...businessTeam, ...team };
            } else {
                return businessTeam;
            }
        });
        return updatedTeams;
    } else {
        return teams;
    }
};

const _editUser = (state, user) => {
    const { users } = state;
    const { id: userid } = user;

    if (userid) {
        const updatedUsers = users.map((businessUser) => {
            const { id } = businessUser;
            if (id === userid) {
                return { ...businessUser, ...user };
            } else {
                return businessUser;
            }
        });
        return updatedUsers;
    } else {
        return users;
    }
};

export const {
    loadAccountInfo,
    clearAccountInfo,
    updateCompanyInfo,
    loadBillingInfo,
    updateBillingInfo,
    clearBillingInfo,
    setProcessingProfile,
    loadUsers,
    loadTeams,
    clearUsers,
    clearTeams,
    editUser,
    loadInvitedUsers,
    clearInvitedUsers,
    editTeam,
} = businessSlice.actions;

export default businessSlice.reducer;
