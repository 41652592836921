// @flow

import * as React from "react";
import styled from "styled-components";
import invariant from "invariant";
import $ from "jquery";

import { showBrowserNotification, parsePayload } from "./utils";
import { subscribe, getToken, listen } from "./push";
import Popup from "../../lib/Popup";
import OpenModal from "../../components/OpenModal";
import { Btn } from "../../components/ButtonDefault";
import { TSpan } from "../../components/intl";
import { __ } from "../../lib/translate";
import { rcookie, setcookie } from "../../lib/utils";

export function initAnonymousNotifications(cb: () => void) {
  getToken(undefined, pushToken => {
    if (pushToken) {
      subscribe(undefined, receiveNotification);
    } else {
      cb();
    }
  });
}

export function initOutisdeNotificationsPopup() {
  window.open("http://127.0.0.1/notifications.html", "notifications", "width=550,height=500");
}

export function initBasicNotifications(
  token: ?string,
  cb: (subscribed?: boolean) => void,
  onBlocked?: () => void
) {
  getToken(
    token,
    pushToken => {
      if (pushToken) {
        cb(true);
      } else {
        subscribe(token, receiveNotification, cb);
      }
    },
    () => {
      if (onBlocked) {
        onBlocked();
      }
    }
  );
}

export function initPushNotifications(token: ?string) {
  //invariant(typeof token === "string" && token.length, "Token is required.");

  getToken(token, pushToken => {
    console.log(token, pushToken);

    if (!pushToken) {
      initYellowLineQuestion(token);
    } else {
      //listen(token, receiveNotification);
      subscribe(token, receiveNotification, function() {
        setcookie("notifications-subscribed", 1, 30);
      });
    }
  });
}

export function initPushNotificationsLogin(token: string) {
  invariant(typeof token === "string" && token.length, "Token is required.");

  getToken(token, pushToken => {
    if (!pushToken) {
      initYellowLineQuestion(token);
    } else {
      subscribe(token, receiveNotification);
    }
  });
}

export function pushSubscribe(token: ?string) {
  console.log("token", token);

  subscribe(token, receiveNotification);
}

function receiveNotification(push) {
  console.log("Received payload:", push);
  showBrowserNotification(parsePayload(push));
}

export function initYellowLineQuestion(token: ?string) {
  //invariant(typeof token === "string" && token.length, "Token is required.");

  if (!shouldAsk()) {
    return;
  }

  getToken(token, pushToken => {
    if (!pushToken) {
      // in public links there is already yellow line markup in the template. just kill it.
      HFN.closeYellowLineJustInCase();
      const removeYellowLine = HFN.yellowLine(
        $(
          [
            "<div>",
            __(
              "subscription_ask",
              "For your better experience, pCloud would like to show you notifications in your current web browser."
            ),
            '<a href="javascript:;">' + __("i_agree", "I agree.") + "</a>",
            "</div>"
          ].join("\n")
        ).outerHTML()
      );

      $(".yellow-text a").on("click", e => {
        pushSubscribe(token);
        removeYellowLine();
        //saveAsk();
      });

      $(".yellow-close").on("click", e => saveAsk());
    } else {
      console.log("User Already subscribed.");
      listen(token, receiveNotification);
    }
  });
}

/*
export function initSubscribeModal(token: string) {
  invariant(typeof token === "string" && token.length, "Token is required.");

  console.log("shouldAsk", shouldAsk());

  if (!shouldAsk()) {
    return;
  }

  getToken(token, pushToken => {
    console.log("pushToken", pushToken);

    if (!pushToken) {
      console.log("get me a token");

      let closeModal;

      closeModal = OpenModal(
        <div>
          <SubscribeModalBody>
            <TSpan id="subscribenotifications_body">subscribenotifications_body text</TSpan>
          </SubscribeModalBody>
          <div className="butt-area">
            <Btn
              color="green"
              inline
              onClick={() => {
                closeModal();
                pushSubscribe(token);
              }}
            >
              <TSpan id="subscribe">Subscribe</TSpan>
            </Btn>
            <Btn
              color="black"
              inline
              onClick={() => {
                saveAsk();
                closeModal();
              }}
            >
              <TSpan id="not_now">Not now</TSpan>
            </Btn>
          </div>
        </div>,
        { title: "Subscribe for notifications" }
      );
    } else {
      console.log("User Already subscribed.");
    }
  });
}
*/

function shouldAsk(): boolean {
  const storageItem = localStorage.getItem("notificationsask");
  if (storageItem) {
    const time = new Date(storageItem).getTime();
    if (time < Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}

function saveAsk(): void {
  localStorage.setItem("notificationsask", new Date(Date.now() + 3600 * 24 * 1000 * 3).toString());
}

function removeAsk(): void {
  localStorage.removeItem("notificationsask");
}

const SubscribeModalBody = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 13px;
  line-height: 19px;
  width: 400px;
`;
