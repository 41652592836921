// @flow

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "styled-components";

export type Props = {
  size?: "small" | "big",
  text: string,
  name: ?string,
  color?: string,
  checked?: boolean,
  disabled?: boolean,
  onChange: any => void
};

class InputStyledRadio extends PureComponent<Props> {
  static defaultProps = {
    size: "big",
    text: "",
    name: "",
    color: "#17bed0",
    checked: false,
    disabled: false,
    onChange: () => {}
  };

  render() {
    const { size, text, name, color, disabled, onChange, ...rest } = this.props;

    return (
      <Container disabled={disabled} color={color}>
        {text}
        <input type="radio" name={name} onChange={onChange} {...rest} />
        <Checkmark size={size} />
      </Container>
    );
  }
}

export default InputStyledRadio;

const Container = styled.label`
  display: block;
  position: relative;
  line-height: 22px;
  padding-left: 35px;
  margin-bottom: 12px;

  cursor: ${props => (props.disabled ? "normal" : "pointer")};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: ${props => (props.disabled ? "normal" : "pointer")};
  }

  &:hover input ~ span {
    background-color: #fafafa;
  }

  input[type="radio"]:checked ~ span {
    background-color: #fff;
    border: ${props => "2px solid" + props.color};
  }

  span:after {
    border-radius: 50%;
    background: ${props => props.color};
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => (props.size === "big" ? "18px" : "14px")};
  height: ${props => (props.size === "big" ? "18px" : "14px")};
  border: 2px solid #b9b9b9;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  input[type="radio"]:checked ~ &:after {
    display: block;
    top: ${props => (props.size === "big" ? "4px" : "3px")};
    left: ${props => (props.size === "big" ? "4px" : "3px")};
    width: ${props => (props.size === "big" ? "10px" : "8px")};
    height: ${props => (props.size === "big" ? "10px" : "8px")};
  }
`;

const InputStyledRadioWithBox = ({ checked, ...rest }) => {
  return (
    <Box checked={checked}>
      <InputStyledRadio checked={checked} {...rest} />
    </Box>
  );
};

export { InputStyledRadioWithBox };

const Box = styled.div`
  label {
    height: 53px;
    padding: 15px 0 0 50px;
    border-radius: 6px;
    border: solid 1px #dadadc;
    border: ${props => (props.checked ? "1px solid #cee895" : "1px solid #dadadc")};
    background-color: ${props => (props.checked ? "#f3fae4" : "#ffffff")};
    box-sizing: border-box;

    span {
      top: 15px;
      left: 15px;
    }
  }
`;

export const InputStyledRadioV2 = ({
  children, 
  name, 
  disabled,
  uncheckedClassName = "smallIcon fa-regular fa-circle",
  checkedClassName = "smallIcon fa-solid fa-circle-dot",
  ...props 
}) => {
  return (
    <RadioContainer disabled={disabled}>
      <input type="radio" name={name} {...props} />
      <RadioButtonWrapper className="radio-btn-wrapper">
        <i className={uncheckedClassName + " unchecked"}></i>
        <i className={checkedClassName + " checked"}></i>
        {children}
      </RadioButtonWrapper>
    </RadioContainer>
  );
};

const RadioContainer = styled.label`
  cursor: ${props => (props.disabled ? "normal" : "pointer")};
  position: relative;
  text-align: left;
  padding: 0;
  
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: ${props => (props.disabled ? "normal" : "pointer")};
  }

  input[type="radio"] ~ span i {
    display: inline-block;
    color: var(--icon-color-lighter);
    &.checked {
      display: none;
    }
  }

  input[type="radio"]:checked ~ span i {
    display: none;
    &.checked {
      display: inline-block;
      color: var(--text-link);
    }
  }
`;

const RadioButtonWrapper = styled.span`
  display: flex;
  gap: var(--spacing-sm);
`
