import { filterObject } from "../../../../lib";

export const runFilterData = (data, filters, currentFilters) => {
  filters.forEach(filter => {
    if (!filter.check && filter.name !== 'search') {
      return;
    }

    const filterValue = currentFilters[filter.name];

    data = filterObject(data, (item) => {
      if (filter.name === 'search') {
        return (
          (
            item.title && item.title.toLowerCase().match(filterValue.toLowerCase())
          ) ||
          (
            item.name && item.name.toLowerCase().match(filterValue.toLowerCase())
          ) ||
          (
            item.firstname && item.lastname && (item.firstname +" "+ item.lastname).toLowerCase().match(filterValue.toLowerCase())
          ) ||
          (
            !item.name && item.email && item.email.toLowerCase().match(filterValue.toLowerCase())
          )
        );
      }

      return filter.check(item, filterValue)
    })
  });
  return data;
}

export const businessUsersPermissionFilterCheck = (item, value) => {
  if (!value) {
    return true;
  }
  if (!item.permissions && !item.invited) {
    return false;
  }
  const ids = Object.keys(value).filter((tmpKey) => {
    return tmpKey !== 'all' && !!value[tmpKey];
  });
  const permissionsids = Object.keys(
    filterObject(item.permissions, (permission) => {
      return !!permission;
    })
  );
  return ids.every((id) =>
    permissionsids &&
    permissionsids.includes(id)
  );
};
export const businessUsersTeamsFilterCheck = (item, value) => {
  if (!value) {
    return true;
  }
  const ids = Object.keys(value).filter((tmpKey) => {
    return tmpKey !== 'all' && !!value[tmpKey];
  });

  return ids.every((id) => item.teamsids.includes(parseInt(id)));
};
export const businessUsersDeletedFilterCheck = (item, value) => {
  return value ? true : item.status !== 'deleted';
};
export const businessTeamsShowAllFilterCheck = (item, value) => {
  return value ? true : item.active;
};