import { HLS_VIDEO_VARIANTS, MAX_VIDEO_FRAME_SIZE } from "./constants";

export function strFit (str, length = 50) {
  if (str.length <= length) {
    return str;
  }

  length -= 3;
  let head = Math.floor(length * .75);
  let tail = length - head;

  return `${str.substring(0, head)} ... ${str.substring(str.length - tail)}`;
};

export function strFitWidthPx(str, maxWidth = 100, font = '14px Roboto') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;

  const measureTextWidth = (text) => {
    return context.measureText(text).width;
  };

  if (measureTextWidth(str) <= maxWidth) {
    return str;
  }

  const ellipsis = '...';
  const ellipsisWidth = measureTextWidth(ellipsis);
  
  const availableWidth = maxWidth - ellipsisWidth;

  const headWidth = Math.floor(availableWidth * 0.85);
  const tailWidth = availableWidth - headWidth;

  let headLength = 0;
  for (let i = 1; i <= str.length; i++) {
    if (measureTextWidth(str.substring(0, i)) > headWidth) {
      break;
    }
    headLength = i;
  }

  let tailLength = 0;
  for (let i = 1; i <= str.length; i++) {
    if (measureTextWidth(str.substring(str.length - i)) > tailWidth) {
      break;
    }
    tailLength = i;
  }

  return `${str.substring(0, headLength)}${ellipsis}${str.substring(str.length - tailLength)}`;
}

export const preloadImage = url => {
  const img = new Image();
  img.src = url;
};

export const getProperThumbnailSize = (num) => {
  if (num % 4 || num % 5) {
    do {
      num--;
    } while ( num % 4 || num % 5 )
  }
  return Math.max(Math.min(num, 2048), 16);
}

export const getBestVideo = variants => {
  let n = 0;
  let k = -1;
  let max = 0;
  for (; n < variants.length; ++n) {
    if (max < variants[n].videobitrate) {
      max = variants[n].videobitrate;
      k = n;
    }
  }

  if (k == -1) {
    return null;
  }

  return variants[k];
};

export const getLowestVideo = variants => {
  let n = 0;
  let k = -1;
  let max = -1;
  for (; n < variants.length; ++n) {
    if (max > variants[n].videobitrate || max == -1) {
      max = variants[n].videobitrate;
      k = n;
    }
  }

  if (k == -1) {
    return null;
  }

  return variants[k];
};

const getVideoUrlData = (variant) => {
  return {
    isoriginal: variant.isoriginal, // Optional param
    bitrate: variant.videobitrate * 1000, // kbps to bps.
    src: HFN.prepUrl(variant),
    // !!!Important to not set type!!! Otherwise onError won't be triggered when link is expired.
    // type: "video/mp4",
    width: variant.width || 0,
    height: variant.height || 0,
    duration: variant.duration,
    transcodetype: variant.transcodetype// mp4 | hls | original
  };
};

const getHLSVideoUrlData = (videoVariants) => {
  const transcodeLinkData = videoVariants.find(({ transcodetype }) => transcodetype === "hls");
  
  if (!transcodeLinkData  || !(transcodeLinkData.hosts && transcodeLinkData.hosts[0] && transcodeLinkData.path && transcodeLinkData.path !== "")) {
    return null;
  }

  return {
    src: HFN.prepUrl(transcodeLinkData),
    type: "application/vnd.apple.mpegurl",
    path: transcodeLinkData.path,
    transcodetype: transcodeLinkData.transcodetype
  }
}

export const getVideoUrls = (videoVariants, allowOriginalVideoVariant = true, disableHLS = false) => {
  let additionalVideoVariants = [];
  let includeOriginal = allowOriginalVideoVariant;
  let originalIsBroken = false;

  let videoUrls = [];

  // Add original as extra option, but it won't be played except manually selected by the user.
  const originalVideoVariant = videoVariants.find(({ isoriginal }) => isoriginal);

  // Check if the file is not a broken video.
  if (
      typeof originalVideoVariant.duration !== "undefined" && Number(originalVideoVariant.duration) <= 0 && 
      typeof originalVideoVariant.videobitrate !== "undefined" && Number(originalVideoVariant.videobitrate) <= 0
  ) {
    includeOriginal = false;
    originalIsBroken = true;
  }
  
  // hls-only changes the priority and skips transcoded variants.
  if (videoVariants.length > 1 && !localStorage.getItem("hls-only")) {
    additionalVideoVariants = videoVariants.filter(
      variant => variant.videocodec == "h264" && fileext(variant.path) == "mp4" && !variant.rotate && !variant.isoriginal && variant.transcodetype === "mp4"
    );

    if (additionalVideoVariants.length > 0) {
      const lowestVideoVariant = getLowestVideo(additionalVideoVariants);
      // lowestVideoVariant.path = "/dpZYMDSQeZNw6953ZSaNbZ7Z3dyVkkZQ5ZZbFkZZhxDa3CEgxnLeCYx8YaVpoSgaC5qV/Screen%20Recording%202024-06-07%20at%2014.55.36.mp4";
      if (originalVideoVariant.height == lowestVideoVariant.height) {
        // Remove the original when we have transcoded video with the same resolution,
        // because sometimes the original video cannot play.
        includeOriginal = false;
      }

      videoUrls.push(getVideoUrlData(lowestVideoVariant));
    }

    if (!$.browser.mobile && additionalVideoVariants.length > 1) {
      const bestVideoVariant = getBestVideo(additionalVideoVariants);

      if (originalVideoVariant.height == bestVideoVariant.height) {
        // Remove the original when we have transcoded video with the same resolution,
        // because sometimes the original video cannot play.
        includeOriginal = false;
      }

      videoUrls.push(getVideoUrlData(bestVideoVariant));
    }
  }

  if (includeOriginal) {
    videoUrls.push(getVideoUrlData(originalVideoVariant));
  }

  // Fallback to original, even if allowOriginalVideoVariant is false.
  if (!originalIsBroken && videoUrls.length === 0) {
    videoUrls.push(getVideoUrlData(originalVideoVariant));
  }

  // (videoUrls.length === 1 && videoUrls[0].isoriginal) -> We have only original to played. 
  // (videoUrls.length === 0) -> Even the original is broken.
  if (!disableHLS && ((videoUrls.length === 1 && videoUrls[0].isoriginal) || videoUrls.length === 0)) {
    // We have only original to played. || Even the original is broken.
    const hlsVideoURL = getHLSVideoUrlData(videoVariants);
    
    if (hlsVideoURL) {
      // Use HLS video when we don't have any transcoded video or original is broken.
      videoUrls = [hlsVideoURL];
    }
  }// Else we have transcoded video -> play it!

  return videoUrls;
};

export const getProperVideoSize = (width, height, variantWidth, variantHeight) => {
  let finalWidth = width > 0 ? width : variantWidth;
  let finalHeight = height > 0 ? height : variantHeight;

  if (finalWidth > variantWidth) {
    // Number multiple of 4 and closest to resized finalHeight.
    finalHeight = Math.floor((finalHeight * variantWidth / finalWidth + 3) / 4) * 4;
    finalWidth = variantWidth;
  }
  
  if (finalHeight > variantHeight) {
    // Number multiple of 4 and closest to resized finalWidth.
    finalWidth = Math.floor((finalWidth * variantHeight / finalHeight + 3) / 4) * 4;
    finalHeight = variantHeight;
  }

  return {
    width: finalWidth,//rotate && (rotate === 90 || rotate === 270) ? finalHeight : finalWidth 
    height: finalHeight// rotate && (rotate === 90 || rotate === 270) ? finalWidth : finalHeight, 
  };
}

export const getProperMaxVideoSize = (width, height) => {
  let maxVideoSizeHLS = {width, height};

  const landscapeOrPortrait = height > width ? "portrait" : "landscape";

  for (const hlsVideoVariant of HLS_VIDEO_VARIANTS[landscapeOrPortrait]) {
    if (width >= hlsVideoVariant.width || height >= hlsVideoVariant.height) {
      // Original video width or height or both are bigger by this frame.
      // We'll have such HLS variant.
      maxVideoSizeHLS = getProperVideoSize(width, height, hlsVideoVariant.width, hlsVideoVariant.height);
    }

    // Have we reached the max video frame inside the player?
    if (hlsVideoVariant.type === MAX_VIDEO_FRAME_SIZE) {
      if (maxVideoSizeHLS.width === 0 || maxVideoSizeHLS.height === 0) {
        // Missing width and height will be here and we'll give default value.
        maxVideoSizeHLS.width = hlsVideoVariant.width;// rotate && (rotate === 90 || rotate === 270) ? hlsVideoVariant.height : hlsVideoVariant.width
        maxVideoSizeHLS.height = hlsVideoVariant.height;// rotate && (rotate === 90 || rotate === 270) ? hlsVideoVariant.width : hlsVideoVariant.height;
      }
      break;
    }
  }

  return maxVideoSizeHLS;
};